import React from 'react'
import Helmet from 'react-helmet'
import { Link } from "gatsby"

import Layout from '../components/layout'
 
import pic12 from '../assets/images/p12.jpg'

const ElArte = ({props, data}) => (
    <Layout>
        <Helmet>
            <title>El Arte - RevelARTE</title>
            <meta name="description" content="Generic Page" />
        </Helmet>

        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="bgtitle"  style={{backgroundImage: `url(${pic12})`}} >
                        <h1>El Arte</h1>
                    </header>
                    <h3>¿Cómo vemos el Arte? </h3>
                    <div class="grid-wrapper">
                        
                        <div class="col-8">
<p>
                    Entendemos el arte como una manera de dialogar con uno mismo y con el entorno. La forma de saber quién soy, o mejor aún, quién voy siendo. Cada uno de nosotrxs es un artista, todos tenemos ese “talento” para crear artísticamente. Ahora bien, para estimular o activar el impulso artístico creativo, podemos recurrir no sólo a las palabras, también podemos entrar en el refugio interno de la pulsión vital, a través de lo sensorial: vista, oído, olfato, gusto y tacto. Esta experiencia es además un acto vital, y la vitalidad es un elemento del arte. 
 
                    <br /> <br />
                    El arte como canal de conocimiento y sabiduría implica posicionarse de manera activa frente a la vida. Nos interroga, sin pretender oír respuestas certeras, verdaderas o correctas. 
                    </p>
</div>

<div class="col-4">
<blockquote>
“Las huellas que dejamos durante este proceso de dar forma no tienen por qué ser valoradas de acuerdo con ningún criterio exterior, sino de acuerdo con nuestro sentido interior de lo que es verdad”.  (B. Allen)
</blockquote>

</div>
</div>
<h3>¿Por qué es importante conectar con la imaginación?</h3>

<div class="grid-wrapper">

<div class="col-8">

Porque como dijimos antes, todxs somos personas creativas, todxs tenemos imágenes internas de nuestro ser, pensar y sentir. Tenemos imágenes de lo que conocemos, pero también de aquello que no conocemos… y son en esas imágenes en las que queremos detenernos, porque cuando les damos forma estamos transitando por un proceso de creación artística.<br /><br />

Para conectar con nuestro artista interno hace falta curiosidad, valor y espacios que
nos permitan entrar en contacto con la imaginación. Parecería que todo esto era más fácil en la niñez, y ahora que somos adultxs nos cuesta permitirnos jugar. La creatividad es juego. El juego como herramienta es para nosotras uno de los medios más importantes para acercarnos los estados creativos. <br /><br />
</div>

<div class="col-4"><blockquote>
Para empezar a recorrer sendas que nos llevan a encuentros y desencuentros de los
lugares menos comunes que habíamos imaginado, el arte nos dice que antes que
pretender enseñarnos algo, sobre todo nos interroga sin pretender oír respuestas.
(G. Mota Botello)</blockquote>



<blockquote>“el juego no es cosa exclusiva de niños;
es algo serio, constructor de subjetividad y por ende de tejido social, sólo podemos
otorgar significado a cada palabra cuando las referenciamos dentro de la praxis”.  (I. Moreno).
</blockquote>

</div>

 
</div>


<ul className="actions">
                                <li><Link to="/creatividad" className="button special big">Creatividad</Link></li>
                                 <li><Link to="/saludmental" className="button special big">Salud Mental</Link></li>

                            </ul>
                           </div>


     </section>
        </div>

    </Layout>
)




export default ElArte